.App {
    display: flex;
    position: relative;
    background-color: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100vw;
    height: 100vh;
    overflow: hidden;
}
body{}
.footer{
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100vw;
    background: #fff;
    width: 100%;

    height: 177px;
}
.footer-container{
    display: flex;
    width: 40%;
    max-width: 800px;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.footer-container img:nth-child(1){
    width: 55%;
}
.footer-container img:nth-child(2){
    width: 49px;
    height: 67px;
}
.footer-container img:nth-child(3){
    width: 30%;
}

.logo_container{
    display: flex;
    justify-content: center;
    align-items: start;

    animation-name: logo-up;
    animation-duration: 0.7s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
}
.logo_inner{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
}
.logo_inner h1{
    font-size: 110px;
    font-family: "Alias";
    font-weight: 700;
}
.logo_inner div{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 39px;
    height: 39px;
    background-color: #DE2110;
    border-radius: 11px;
    margin-bottom: 0px;
    animation-name: dot-pump;
    animation-duration: 0.7s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
}
.logo_inner img{
    width: 20px;
    opacity: 0;
    animation-name: eagle-pump;
    animation-duration: 0.7s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
}

.p-first{
    font-family: Inovasi;
    font-size: 38.36px;
    font-weight: 400;
    line-height: 42.19px;
    text-align: center;
    opacity: 0;
    margin-bottom: 50px;
    animation: 800ms cubic-bezier(0.175, 0.185, 0.32, 1.175) 0s 1 normal forwards running fade-in-up;
    animation-delay: 2.3s;
    animation-fill-mode: forwards;
}

.p-second{
    font-family: Inovasi;
    font-size: 19.21px;
    font-weight: 400;
    line-height: 21.13px;
    text-align: center;
    opacity: 0;
     
    margin-bottom: 23px;
    animation: 800ms cubic-bezier(0.175, 0.185, 0.32, 1.175) 0s 1 normal forwards running fade-in-up;
    animation-delay: 2.7s;
    animation-fill-mode: forwards;
}

.progressbar{
    position: relative;
    display: flex;
    justify-content: start;
    align-items: center;
    width: 700px;
    height: 59px;
    opacity: 0;
    border-radius: 29.5px;
    border: 2px solid #000;
    overflow: hidden;
    animation: 800ms cubic-bezier(0.175, 0.185, 0.32, 1.175) 0s 1 normal forwards running fade-in-up;
    animation-delay: 3s;
    animation-fill-mode: forwards;
}
.progressbar-inner{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #032A31;
    width: 10%;
    height: 105%;
    border-radius: 29.5px;
    margin-left: -2px;
    margin-top: 1px;
    animation-name: progress-grow;
    animation-duration: 1.5s;
    animation-delay: 3s;
    animation-fill-mode: forwards;
}

.progressbar-inner img{
    position: absolute;
    right: -3px;
    top: 0;
    width: 60px;
    height: 60px;
    border-radius: 29.5px;
    margin-bottom: 1px;
    background-color: #032A31;
}
.progressbar-inner p{
    font-family: Inovasi;
    font-size: 23.76px;
    font-weight: 400;
    line-height: 26.14px;
    text-align: center;
    color: #fff;    
    white-space: nowrap;
    opacity: 0;
    animation-name: progress-text;
    animation-duration: 1s;
    animation-delay: 3.1s;
    animation-fill-mode: forwards;
}

.socials-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;
    margin-top: 30px;
    padding-bottom: 0px;
    animation-name: padding;
    animation-duration: 1s;
    animation-delay: 2s;
    animation-fill-mode: forwards;

}


.social-block{
    width: 82px;
    height: 82px;
    border-radius: 18px;
    border: 3px solid #032A31;
    opacity: 0;
    z-index: 2;
}

.social-block-inner{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 14px;
    background-color: #fff;
    border: 3px solid #C8F11D;
    transition: all 0.3s ease;
}
.social-block-inner:hover{
    background-color: #C8F11D;
}

.social-block-inner a{
    position: absolute;
    width: 100%;
    height: 100%;
}

.socials-container .social-block:nth-child(1){
    animation: 600ms cubic-bezier(0.175, 0.185, 0.32, 1.175) 0s 1 normal forwards running pop-in;
    animation-delay: 3.5s;
    animation-fill-mode: forwards;
}
.socials-container .social-block:nth-child(2){
    animation: 600ms cubic-bezier(0.175, 0.185, 0.32, 1.175) 0s 1 normal forwards running pop-in;
    animation-delay: 3.7s;
    animation-fill-mode: forwards;
}
.socials-container .social-block:nth-child(3){
    animation: 600ms cubic-bezier(0.175, 0.185, 0.32, 1.175) 0s 1 normal forwards running pop-in;
    animation-delay: 3.9s;
    animation-fill-mode: forwards;
}
.socials-container .social-block:nth-child(4){
    animation: 600ms cubic-bezier(0.175, 0.185, 0.32, 1.175) 0s 1 normal forwards running pop-in;
    animation-delay: 4.1s;
    animation-fill-mode: forwards;
}

.crypto-carousel-container{
    position: absolute;
    top: 101%;
    animation-name: carousel;
    animation-duration: 1s;
    animation-delay: 3.1s;
    animation-fill-mode: forwards;
}

.crypto-carousel{
    width: 55vw;
    animation-name: rotate; 
    animation-duration: 60s; 
    max-width: 1280px;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    border-radius: 50%;
}

.crypto-carousel:hover {
    animation-play-state: paused;
}
  




@keyframes dot-pump {
    0%   {width: 39px; height: 39px;}
    100% {width: 63px; height: 63px;border-radius: 20px;}
}
@keyframes eagle-pump {
    0%   {width: 19px; opacity: 0;}
    100% {width: 37px; opacity: 1;}
}

@keyframes logo-up {
    0%   {height: 110px;}
    100% {height: 180px;}
}

@keyframes progress-grow {
    0%   {width: 10%;}
    100%   {width: 70%;}
}
@keyframes progress-text {
    0%   {opacity: 0;}
    100%   {opacity: 1;}
}

@keyframes carousel {
    0%   {top:101%;}
    100%   {top: 85%;}
}

@keyframes rotate {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}

@keyframes padding {
    from {padding-bottom: 0;}
    to {padding-bottom: 150px;}
}

.p-third{
    max-width: 600px;
    margin-bottom: 0 !important;
    margin-top: 24px;
}


@media screen and (max-width: 1024px) {
    .footer-container{
        width: 70%;
    }
    .p-first{
        font-family: Inovasi;
        font-size: 40px;
        font-weight: 400;
        line-height: 37px;
        margin-bottom: 16px;
    }
    @keyframes logo-up {
        0%   {height: 110px;}
        100% {height: 140px;}
    }
    .p-second{
        margin-bottom: 50px;
    }
  }

@media screen and (max-width: 480px) {
    .p-first{
        font-family: Inovasi;
        font-size: 20px;
        font-weight: 400;
        line-height: 19px;
        margin-bottom: 20px;
    }
    .logo_inner h1{
        font-family: Alias;
        font-size: 35.83px;
        font-weight: 700;
        line-height: 35.4px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    }
    .logo_inner{
        gap: 6px;
    }
    .logo_inner div{
        width: 12px;
        height: 12px;
        border-radius: 3px;
        margin-bottom: 0px;
    }
    .p-second{
        font-family: Inovasi;
        font-size: 14px;
        font-weight: 400;
        line-height: 15.3px;
        text-align: center;
        margin-bottom: 29px;
    }
    .progressbar{
        width: 90%;
        height: 32px;
    }
    .progressbar-inner p{
        font-family: Inovasi;
        font-size: 13px;
        font-weight: 400;
        line-height: 13.2px;
    }
    .progressbar-inner img{
        width: 32px;
        height: 32px;
        top: -2px;
        margin-bottom: 3px;
    }

    .social-block{
        width: 40px;
        height: 40px;
        border-radius: 9px;

    }
    .socials-container{
        gap: 20px;
    }
    .social-block-inner{
        border-width: 1.5px;
        border-radius: 5px;
    }
    .social-block-inner img{
        width: 20px;
        height: 20px;

    }
    .crypto-carousel{
        width: 123vw;
    }
    .footer-container img:nth-child(2){
        width: 25px;
        height: 30px;
    }
    @keyframes carousel {
        0%   {top:101%;}
        100%   {top: 70%;}
    }
    @keyframes logo-up {
        0%   {height: 35.41px;}
        100% {height: 90px;}
    }
    @keyframes dot-pump {
        0%   {width: 12px; height: 12px;}
        100% {width: 24px; height: 24px;border-radius: 6.6px;}
    }
    @keyframes eagle-pump {
        0%   {width: 8px; opacity: 0;}
        100% {width: 14px; opacity: 1;}
    }
    
}